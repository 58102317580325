import React from "react";
import SingleServicePage from "../../components/SingleServicePage";
import withLayout from "../../layout";
import {injectIntl} from "react-intl";
import SEO from "../../components/SEO";
import mantegna from '../../images/portfolio/Mantegna.mp4';
import MantegnaThumb from '../../images/portfolio/MANTEGNA_thumbnail.webp';
import drop from '../../images/service_detail_drops/green.svg';
import {BulletGreenIcon} from "../../images/icons";
/**
 * note that the key we defined in role is needed for building the SingleServicePage
 * component with the right translation strings and media
 * @returns { SingleServicePage }
 * @constructor
 */

const SingleServicePageWrapper = ({intl}) => {
    const IntlTextObj = {
        title: 'communication.title',
        colorName: 'acquamarine',
        color: '#10CFC4',
        drop: drop,
        bullet: <BulletGreenIcon/>,
        fromService: 'communication',
        paragraphs: [
            {title: 'communication.social_management_title', text: 'communication.social_management_desc'},
            {title: 'communication.video_production_title', text: 'communication.video_production_desc'}
        ],
        services_detail: ['communication.detail_1','communication.detail_2','communication.detail_3','communication.detail_4','communication.detail_5',
            'communication.detail_6','communication.detail_7','communication.detail_8','communication.detail_9','communication.detail_10',
            'communication.detail_11','communication.detail_12'],
        clients: [
            {
                video: mantegna,
                title: 'portfolio.mantegna.title',
                poster: MantegnaThumb,
                subtitle: 'portfolio.mantegna.description',
                to: '/case-study/mantegna',
            }
        ]
    };

    const keywords = intl.formatMessage({id: 'communication.meta_keywords'}).split(',') || [];

    return <>
        <SEO title={intl.formatMessage({id: 'communication.meta_title'})}
             description={intl.formatMessage({id: 'communication.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <SingleServicePage IntlTextObj={IntlTextObj} intl={intl}/>
    </>
};

const customProps = {
    localeKey: "communication", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleServicePageWrapper));
